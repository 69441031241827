@import '../abstracts/variables.scss';
@import '../abstracts/mixins.scss';
@import '../sections/base.scss';

.pb-events-panel {
    .alpha {
        margin-bottom: 1.5em;
    }
    .leftcol {
        text-align: right;
        h3 + a {
            color: $atlasred;
            margin-top: 2.25rem;
        }
    }
    .carousel-wrapper {
        margin-top: 3em;
        overflow: hidden;
        position: relative;
        .greyfade {
            background-image: linear-gradient(to right,rgba($grey, 0%),$grey, $grey,);
            width: 300px;
            height: 100%;
            top: 0;
            right: -60px;
            position: absolute;
            z-index: 10;
        }
    }
    .events-carousel {
        .slick-track {
            display: flex;
            gap: 1.5em;
            &:before, &:after {
                position: absolute;
            }
        }
        .slick-slide {
            border-right: 1.5px solid $atlasred;
            .slide-item {
                flex: 1;
                .inner {
                    padding: 0 1.5em 0 0;
                }
                span {
                    display: block;
                    &.event-date {
                        color: $atlasred;
                        font-size: 20px;
                        margin-bottom: 0.75em;
                        sup {
                            font-size: 12px;
                        }
                    }
                    &.event-loc {
                        margin-bottom: 1em;
                    }
                }
                h4 {
                    font-family: 'Mont-semi';
                    font-size: 17px;
                }
            }
        }
    }
    .slick-nav {
        border-top: none;
        margin: 2em 0 0;
        padding-top: 0;
        text-align: right;
        float: right;
        .slick-btn {
            background-image: url('../../assets/images/icon_link_arrow.svg');
            background-size: cover;
            width: 35px;
            height: 35px;
            &:hover {
                background-color: rgba($white, 45%);
            }
        }
    }
    @include bpr-media-breakpoint(down, xxl) {
        .posts-carousel {
            margin-left: 0;
        }
	}
    @include bpr-media-breakpoint(down, lg) {
        .carousel-wrapper {
            .greyfade {
                display: none;
            }
        }
        .events-carousel {
            .slick-track {
                gap: 0;
            }
            .slick-slide {
                border-right: 0.75px solid $atlasred;
                border-left: 0.75px solid $atlasred;
                .slide-item {
                    .inner {
                        padding: 0 1em;
                    }
                }
            }
        }
    }
    @include bpr-media-breakpoint(down, md) {
        .leftcol {
            text-align: center;
        }
        .carousel-wrapper {
            text-align: center;
        }
        .slick-nav {
            margin: 2em auto 0;
            text-align: center;
            float: none;
        }
	}
    @include bpr-media-breakpoint(down, sm) {
        .events-carousel {
            .slick-slide {
                border-right: none;
                border-left: none;
            }
        }
    }
}