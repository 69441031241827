:root {
  --pbpadding: 3em;
  --pbdoublepadding: 6em;
  --pbtriplepadding: 9em;
}

:root {
  --base-font-size: 17px;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  color: #000000;
  font-size: 17px;
  font-family: "Mont-light", Arial;
  font-variation-settings: "wght" 400;
  background-color: white;
  position: relative;
}

p {
  font-size: var(--base-font-size);
  line-height: 1.65em;
  margin-bottom: 1.25em;
}

strong,
b {
  font-family: "Mont-bold";
  font-variation-settings: "wght" 600;
}

img {
  height: auto;
}

h2 + p,
h3 + p,
h4 + p,
h5 + p,
h5 + ul {
  margin-top: 1rem;
}

p + .keylink {
  margin-top: 1em;
}

p + ul {
  margin-bottom: 2em;
}

a:not(.nav-link, .skiplink, .reset-link), .link {
  color: #000;
  text-decoration: none;
}
a:not(.nav-link, .skiplink, .reset-link):hover, .link:hover {
  text-decoration: underline;
}

article.text-side a, article a {
  text-underline-offset: 0.3em;
}

.reset-link {
  text-decoration: none !important;
  color: black !important;
}
.reset-link:hover {
  text-decoration: underline !important;
  text-underline-offset: 3px;
}

:is(.backto.backto) {
  text-decoration: none;
  opacity: 0.6;
  margin-top: 2rem;
  display: inline-block;
}
:is(.backto.backto) img {
  width: 27px;
  margin-right: 5px;
}
:is(.backto.backto):hover {
  text-decoration: underline;
}

.mysuperflexteststyle {
  background-color: red;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

*:focus:not(:focus-visible) {
  outline: none !important;
}

div.objectfit {
  height: 100%;
}
div.objectfit img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

svg.icon {
  display: inline-block;
  width: 1.8rem;
  height: 1.8rem;
  fill: currentColor;
  position: relative;
  top: 3px;
  margin: -2px 5px 0 0;
}

.skiplink {
  position: absolute;
  left: -9999em;
}
.skiplink:focus {
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  font-size: 2rem;
  z-index: 1000;
  background: #000000;
  padding: 1rem 1.8rem;
  border: 4px solid white;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: auto;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

.withflex {
  display: flex;
}

.centred {
  text-align: center;
}

.align-right {
  text-align: right;
}

.gorel {
  position: relative;
}

.noflow {
  overflow: hidden;
}

.rounded-box {
  border-radius: 18px;
  overflow: hidden;
}

.bg-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-blue {
  background-color: #db0132;
  color: #FFF;
}

.bg-grey {
  background-color: #ededed;
}

.bg-greylt {
  background-color: #F5F5F5;
}

.bg-sky {
  background-color: #ededed;
}

.gowhite {
  color: #FFF;
}

.goblue {
  color: #db0132;
}

.bordered-blue {
  border: 0.5px solid #db0132;
}

.with-margin-top {
  margin-top: var(--pbpadding);
}

.with-margin-bottom {
  margin-bottom: var(--pbpadding);
}

.nomargin-top {
  margin-top: 0 !important;
}

.nomargin-bottom {
  margin-bottom: 0 !important;
}

.nopadding-top {
  padding-top: 0 !important;
}

.nopadding-bottom {
  padding-bottom: 0 !important;
}

.noflow {
  overflow: hidden;
}

.smaller-text {
  font-size: 13px;
}
.smaller-text h2 {
  font-size: 26px;
}
.smaller-text p {
  font-size: 13px;
}

@media (max-width: 768px) {
  p + .keylink {
    margin-top: 0.25em;
  }
}
.pb-events-panel .alpha {
  margin-bottom: 1.5em;
}
.pb-events-panel .leftcol {
  text-align: right;
}
.pb-events-panel .leftcol h3 + a {
  color: #db0132;
  margin-top: 2.25rem;
}
.pb-events-panel .carousel-wrapper {
  margin-top: 3em;
  overflow: hidden;
  position: relative;
}
.pb-events-panel .carousel-wrapper .greyfade {
  background-image: linear-gradient(to right, rgba(237, 237, 237, 0), #ededed, #ededed);
  width: 300px;
  height: 100%;
  top: 0;
  right: -60px;
  position: absolute;
  z-index: 10;
}
.pb-events-panel .events-carousel .slick-track {
  display: flex;
  gap: 1.5em;
}
.pb-events-panel .events-carousel .slick-track:before, .pb-events-panel .events-carousel .slick-track:after {
  position: absolute;
}
.pb-events-panel .events-carousel .slick-slide {
  border-right: 1.5px solid #db0132;
}
.pb-events-panel .events-carousel .slick-slide .slide-item {
  flex: 1;
}
.pb-events-panel .events-carousel .slick-slide .slide-item .inner {
  padding: 0 1.5em 0 0;
}
.pb-events-panel .events-carousel .slick-slide .slide-item span {
  display: block;
}
.pb-events-panel .events-carousel .slick-slide .slide-item span.event-date {
  color: #db0132;
  font-size: 20px;
  margin-bottom: 0.75em;
}
.pb-events-panel .events-carousel .slick-slide .slide-item span.event-date sup {
  font-size: 12px;
}
.pb-events-panel .events-carousel .slick-slide .slide-item span.event-loc {
  margin-bottom: 1em;
}
.pb-events-panel .events-carousel .slick-slide .slide-item h4 {
  font-family: "Mont-semi";
  font-size: 17px;
}
.pb-events-panel .slick-nav {
  border-top: none;
  margin: 2em 0 0;
  padding-top: 0;
  text-align: right;
  float: right;
}
.pb-events-panel .slick-nav .slick-btn {
  background-image: url("../../assets/images/icon_link_arrow.svg");
  background-size: cover;
  width: 35px;
  height: 35px;
}
.pb-events-panel .slick-nav .slick-btn:hover {
  background-color: rgba(255, 255, 255, 0.45);
}
@media (max-width: 1280px) {
  .pb-events-panel .posts-carousel {
    margin-left: 0;
  }
}
@media (max-width: 920px) {
  .pb-events-panel .carousel-wrapper .greyfade {
    display: none;
  }
  .pb-events-panel .events-carousel .slick-track {
    gap: 0;
  }
  .pb-events-panel .events-carousel .slick-slide {
    border-right: 0.75px solid #db0132;
    border-left: 0.75px solid #db0132;
  }
  .pb-events-panel .events-carousel .slick-slide .slide-item .inner {
    padding: 0 1em;
  }
}
@media (max-width: 768px) {
  .pb-events-panel .leftcol {
    text-align: center;
  }
  .pb-events-panel .carousel-wrapper {
    text-align: center;
  }
  .pb-events-panel .slick-nav {
    margin: 2em auto 0;
    text-align: center;
    float: none;
  }
}
@media (max-width: 480px) {
  .pb-events-panel .events-carousel .slick-slide {
    border-right: none;
    border-left: none;
  }
}